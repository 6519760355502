<template>
  <CloudBanner />
  <HeaderService />
  <CloudHeader />
  <CloudSection />
  <!-- <Cloudicons /> -->
  <Contact style="background: #f6f7fd" />
  <Footer />
</template>

<script>
import CloudBanner from "@/components/services/intocloud/CloudBanner.vue";
import HeaderService from "@/components/services/HeaderService.vue";
import Footer from "@/components/Footer.vue";
import CloudSection from "@/components/services/intocloud/CloudSection.vue";
import CloudHeader from "@/components/services/intocloud/CloudHeader.vue";
// import Cloudicons from "@/components/services/intocloud/CloudIcons.vue";
import Contact from "@/components/landing/Contact.vue";

export default {
  components: {
    HeaderService,
    Footer,
    CloudSection,
    CloudHeader,
    // Cloudicons,
    CloudBanner,
    Contact,
  },
};
</script>
