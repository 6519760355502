<template>
  <section id="features" class="services">
    <div class="container" data-aos="fade-up">
      <div class="section-title text-center p-4">
        <h1>InTo Cloud - Features</h1>
      </div>

      <div class="row pt-4">
        <div
          class="col-lg-4 col-md-6 col-12 d-flex align-items-stretch pt-4"
          v-for="(obj, key) in featureareaJSON"
          :key="key"
        >
          <div class="icon-box">
            <div class="iconheader d-flex align-items-stretch">
              <Image :fpath="obj.path" class="serviceimage" />
              <p>{{ obj.caption }}</p>
            </div>
            <h4 class="softwarehead">{{ obj.header }}</h4>
            <p>{{ obj.desc }}</p>
            <div
              v-for="(tag, index) in obj.tags"
              :key="'tag-' + index"
              class="taghead"
            >
              <h6 class="softwarehead"><ICON_Tag /> {{ tag }}</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Image from "@/components/Image.vue";
import ICON_Tag from "@/assets/icons/Tag.vue";
export default {
  components: {
    Image,
    ICON_Tag,
  },
  data: () => ({
    featureareaJSON: [
      {
        path: "WebDesign.svg",
        header: "Web Design & Development",
        caption:
          "Your website is your Business's first impression to the world. It's the perfect platform to promote and sell your life's work to the right audience.",
        desc: "That's why, We handcraft each website with our creative team to achieve the perfect balance of aesthetics and usability, To ensure that their brand's vision, needs, and uniqueness are upheld.",
        tags: ["UI/UX Design", "Responsive Website", "Domain & Hosting + SSL"],
      },
      {
        path: "GraphicDesign.svg",
        header: "Graphic Design",
        caption:
          "Graphic Design is a craft where concepts, ideas, and abstracts are presented visually to communicate messages.",
        desc: "That's why, We handcraft it with our own design system to make them visually appetizing to its spectators. We honor the brand's idea to make their products stand out in the crowd.",
        tags: ["Logo Design", "Promotional Design", "Infographic Design"],
      },
      {
        path: "DigitalPromotion.svg",
        header: "Digital Promotion",
        caption:
          "Marketing is an effective way to drive a business. And the race to grab the attention of the audience between b2b & b2c has increased drastically, therefore you need to do a huge shout to promote your products.",
        desc: "likely, We are not only aimed to provide you with world-class design and development but also to help you with the most engaging services to attract your target audience at ease.",
        tags: ["SEO", "Social Media Marketing", "Brand Consulting"],
      },
    ],
  }),
};
</script>
<style scoped>
.iconheader {
  background: linear-gradient(to bottom right, #4da1ff 30%, #abfbf2 100%);
  padding: 20px;
  border-radius: 10px;
  flex-direction: initial;
}
.iconheader p {
  margin: 0px 15px !important;
  color: #000;
}
h6.softwarehead svg {
  margin: 0px 5px 0px 0px;
}
.icon-box:hover {
  background: #f7f9fd;
  border-radius: 10px;
  transform: translateY(-2px);
  cursor: pointer;
  border: 1px solid #4a90e2;
}
img.serviceimage {
  width: 25%;
}
section#features {
  padding: 110px 30px 80px 30px;
  background: #f9f9f9;
}

.services .icon-box {
  padding-bottom: 30px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}
section#Softwareicon {
  padding: 50px 30px 10px 30px;
}
.icon-box h6 {
  font-weight: 700;
  margin: 10px 30px 10px 30px;
  font-size: 16px;
  background-color: #dadcdf;
  border-radius: 10px;
  padding: 10px;
  width: fit-content;
}
.services .icon-box h4 {
  font-weight: 700;
  margin: 30px 30px 20px 30px;
  font-size: 16px;
}
.embservice {
  margin-left: 70px;
}
.services .icon-box h4 {
  color: #05579e;
  transition: 0.3s;
}

.services .icon-box h4 a:hover {
  color: #0880e8;
}

.services p {
  line-height: 24px;
  font-size: 14px;
  margin: 10px 33px;
}
@media (max-width: 998px) and (min-width: 0px) {
  section#features {
    padding: 40px;
  }
}
</style>
